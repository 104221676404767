import React, { useEffect } from 'react';

function CalendlyEmbed() {
    useEffect(() => {
        const loadCalendlyScript = () => {
            return new Promise((resolve, reject) => {
                if (document.getElementById('calendly-script')) {
                    resolve();
                    return;
                }

                const script = document.createElement('script');
                script.id = 'calendly-script';
                script.src = 'https://assets.calendly.com/assets/external/widget.js';
                script.async = true;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            });
        };

        const initializeCalendly = async () => {
            await loadCalendlyScript();
            window.Calendly.initInlineWidget({
                url: 'https://calendly.com/flick-sales/30min',
                parentElement: document.getElementById('calendly-embed'),
                prefill: {
                    name: localStorage.getItem('userName'),
                    email: localStorage.getItem('userEmail')
                }
            });
        };

        initializeCalendly();
    }, []);

    return (
        <div 
            id="calendly-embed" 
            style={{ 
                width: '100vw', 
                height: '100vh', 
                overflow: 'hidden' 
            }} 
        />
    );
}

export default CalendlyEmbed;
