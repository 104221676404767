import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import '../flickform/form.scss'

function Success() {

    const navigate = useNavigate();
    const [email, setEmail] = useState();

    useEffect(() => {
        const emailAddress = localStorage.getItem('userEmail');
        
        if (emailAddress) {
          setEmail(emailAddress);
        }
      }, []);

    const handleFlick = () => {
        window.open('https://getflick.app/', '_blank');
    };

    const handleHome = () => {
        navigate('/')
    }
    

  return (
    <div className="success-page">
        <div className="flick-logo">
             <img onClick={handleFlick} src={process.env.PUBLIC_URL + "/images/flick-logo.svg"} alt="logo" style={{cursor:"pointer"}} />
        </div>
        <div className='success-wrapper'>
            <div className='success'>
            <img src={process.env.PUBLIC_URL + "/images/success.svg"} alt="success"  className='success-logo'/>
                <p className='successful'>Successful</p>
                <p className='sent-to'>Congratulations, We’ve sent an email to <br/> <span style={{color:"#000"}}>“{email}”</span></p>
                <button className="btn" onClick={handleHome}>Go Home</button>
            </div>
        </div>
    </div>
  )
}

export default Success