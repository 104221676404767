import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import './form.scss'
import axios from "axios";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function FlickForm() {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [formData,setFormData] = useState({
    name:"",
    email:"",
    phone:"",
    company_name:"",
    selectedOptions: []
  });


  const handleFlick = () => {
    window.open('https://getflick.app/', '_blank');
  };
const  handleInputChange = (event)=>{
  const { name, value } = event.target;
  setFormData((prevFormData) => ({
    ...prevFormData,
    [name]: value,
  }));
}
const handleSelectChange = (selectedOptions) => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    selectedOptions: selectedOptions
  }));
};

  const handleSubmit = async (e) => {
    // https://calendly.com/stanzealot/30min
    e.preventDefault();
    setIsLoading(true)
    try {

      if (formData.phone && (formData.phone.length === 0 || formData.phone.length < 10)) {
        toast.warning("Phone number must be at least 10 characters long");
        setIsLoading(false);
        return;
      }

      
      let data = JSON.stringify({
        // "email": "dipo@dthriz.com",
        // "name": "Dipo johnes",
        phone: formData.phone.trim(),
        name: formData.name.trim(),
        email: formData.email.trim(),
        company_name: formData.company_name.trim(),
        // category: formData.selectedOptions.map(option => option.value)
        category: {
          local: formData.selectedOptions.some(option => option.value === 'local'),
          global: formData.selectedOptions.some(option => option.value === 'global'),
          multi: formData.selectedOptions.some(option => option.value === 'multi'),
          currency: formData.selectedOptions.some(option => option.value === 'currency'),
          financial_data: formData.selectedOptions.some(option => option.value === 'financial_data'),
          identity: formData.selectedOptions.some(option => option.value === 'identity'),
          direct_debit: formData.selectedOptions.some(option => option.value === 'direct_debit')
        }
      });
     

     
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://flickopenapi.co/mail/mail_sending',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      setIsLoading(false);
      localStorage.setItem('userEmail', formData.email);
      localStorage.setItem('userName', formData.name);
   
      // To track the follow up with Brevo
      window.sendinblue.track('Follow up', {
        email: formData.email,
        name: formData.name
    });

    
    // Open Calendly popup
    navigate('/calendly')


    }
    catch(error){
      setIsLoading(false);
      console.log(error)
    }
  }

  const animatedComponents = makeAnimated();

  const options = [
    { value: 'local', label: ' Local Payment' },
    { value: 'global', label: 'Global Payment' },
    { value: 'multi', label: 'Multi-Currency IBANs' },
    { value: 'currency', label: 'Currency Swap' },
    { value: 'financial_data', label: 'Financial Data' },
    { value: 'identity', label: 'Identity / KYC' },
    { value: 'direct_debit', label: 'Direct Debit' },
  ]

  

  return (
    <div className="email-form">
      {/* Right Side */}

      <div className="right-side">
        <div className="right-view">
          <div className="right-view-container">
            <div className="flick-logo">
             <img onClick={handleFlick} src={process.env.PUBLIC_URL + "/images/flick-logo.svg"} alt="login-logo" style={{cursor:"pointer"}} />
            </div>

            <div className="contact-form">
              <div className="contact-sales">
                <h1 className="contact">Contact Sales</h1>
                <h4 className="ready-to-build">Ready to build the future of financial services? Fill in the form below and we’ll be in touch soon.</h4>
              </div>
              <form className="email-contact-form" onSubmit={handleSubmit}>
                <div className="form-group">
                      <label className="label">Name*</label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        className="input-field"
                        onChange={handleInputChange}
                        required
                      />
                </div>
                <div className="form-group">
                      <label className="label">Email*</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        className="input-field"
                        onChange={handleInputChange}
                        required
                      />
                </div>
                <div className="form-group">
                      <label className="label">Phone Number*</label>
                      <input
                        type="text"
                        name="phone"
                        placeholder="e.g. 08136902667"
                        className="input-field"
                        onChange={handleInputChange}
                        required
                      />
                </div>
                <div className="form-group">
                      <label className="label">Company Name*</label>
                      <input
                        type="text"
                        name="company_name"
                        placeholder="e.g. Flick"
                        className="input-field"
                        onChange={handleInputChange}
                        required
                      />
                </div>
                <div>
                <label className="label">Products*</label>
                <Select 
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  isMulti
                  options={options}
                  onChange={handleSelectChange}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderRadius: "8px",
                    }),
                  }}
                  
                />
                </div>
                <button className="btn">
                  {isLoading ? (
                    <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                    
                  ) : (
                    <>
                      Submit
                      <img src={process.env.PUBLIC_URL + "/images/arrow-right.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "10px" }}/>
                    </>
                  )}
                </button>
              </form>
            </div>
            <div className="footer">
              <div className="foot-text">© 2024 Flick</div>
              <div className="foot-text"><img src={process.env.PUBLIC_URL + "/images/mail.svg"} alt="mail"  className="foot-img"/>sales@getflick.app</div>
            </div>
          </div>
        </div>
      </div>

      {/* Left Side */}

      <div className="left-side">
        <div className="left-view">
          <h1 className="one-api">One API powering all your products</h1>
          <h4 className="one-stop">One-stop integration for seamless access to financial data, identity, and payments across Africa.</h4>
        </div>
        <div className="circle-logo">
         <img src={process.env.PUBLIC_URL + "/images/flick-circle-logo.svg"} alt="logo" className="form-logo"/>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default FlickForm;
