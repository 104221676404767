import './App.css';
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import FlickForm from './components/flickform/FlickForm';
import Success from './components/success/Success';
import CalendlyEmbed from './components/calendly/CalendlyEmbed';

function App() {
  return (
    <Router>
    <Routes>
      <Route path='/' element={<FlickForm />}/>
      <Route path='/calendly' element={<CalendlyEmbed />}/>
      <Route path='/success' element={<Success />}/>
    </Routes>
  </Router>
  );
}

export default App;
